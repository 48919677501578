"use client";

import Link from "next/link";

export const AccessibilityIcon = () => {
  const handleGrayscale = () => {
    if (typeof window !== "undefined") {
      const grayscale = window.localStorage.getItem("grayscale");
      if (grayscale) {
        document.body.style.filter = "none";
        window.localStorage.removeItem("grayscale");
      } else {
        document.body.style.filter = "grayscale(1)";
        window.localStorage.setItem("grayscale", "yes");
      }
    }
  };

  return (
    <li>
      <Link href="#" className="hover:cursor-pointer" onClick={handleGrayscale}>
        <span className="basf-icon text-2xl">&#xF102;</span>
      </Link>
    </li>
  );
};
