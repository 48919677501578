"use client";

import Link from "next/link";

export const LanguageIcon = () => {
  const switchLanguage = (lang: "en" | "tr") => {
    if (typeof window !== "undefined") {
      (window as any)?.Weglot?.switchTo(lang);
    }
  };

  return (
    <li className="group/category skip-lang relative">
      <Link href="#" className="hover:cursor-pointer">
        <span className="basf-icon text-2xl">&#xF13A;</span>
      </Link>
      <div className="invisible absolute z-10 w-44 translate-y-1/2 bg-transparent pt-7 font-normal opacity-0 duration-500 group-hover/category:visible group-hover/category:translate-y-0 group-hover/category:opacity-100">
        <ul className="m-0 list-none divide-y border bg-white p-0 shadow-lg">
          <li className="group/sub-category relative">
            <Link
              className="flex items-center justify-between px-3 py-2 text-lg text-slate-700 hover:bg-slate-100"
              href="#"
              onClick={() => switchLanguage("en")}
            >
              <span>English</span>
            </Link>
          </li>
          <li className="group/sub-category relative">
            <Link
              className="flex items-center justify-between px-3 py-2 text-lg text-slate-700 hover:bg-slate-100"
              href="#"
              onClick={() => switchLanguage("tr")}
            >
              <span>Türkçe</span>
            </Link>
          </li>
        </ul>
      </div>
    </li>
  );
};
